.partner-logo {
  max-width: 150px; // Limite la taille des logos pour uniformité
  margin: 20px; // Espace autour de chaque logo
  filter: grayscale(100%); // Optionnel : met les logos en nuances de gris
  transition: filter 0.3s ease-in-out;

  &:hover {
    filter: grayscale(0); // Les logos reprennent leurs couleurs au survol
  }
}
