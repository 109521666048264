div.BtnReserver {
  background: green !important;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2rem;
  transition: all 0.3s;
  &:hover {
    background-color: #f7b733;
    color: #fff;
  }
}
