@charset "UTF-8";
.the-building-wrap .text-description {
  padding: 0 16%; }
  .the-building-wrap .text-description [class*='col-']:first-child {
    border-right: 1px solid #ebebeb; }
  @media (max-width: 992px) {
    .the-building-wrap .text-description {
      padding: 0 15px; } }

.the-building-wrap .box-counter {
  position: relative; }
  .the-building-wrap .box-counter:before {
    content: '';
    background: #cabe9f;
    height: 3px;
    width: 100px;
    right: 0;
    top: 50%;
    position: absolute;
    right: -20%; }
    @media (max-width: 992px) {
      .the-building-wrap .box-counter:before {
        content: none; } }

.the-building-wrap .counter-wrap [class*='col-']:last-child .box-counter:before {
  content: none; }

.gallerry-wrap {
  padding: 0px 60px 60px 60px; }
  @media (max-width: 992px) {
    .gallerry-wrap {
      padding: 0 30px;
      margin-bottom: 30px; } }
  @media (min-width: 992px) {
    .gallerry-wrap [class*='col-'] {
      padding-left: 10px;
      padding-right: 10px; } }
  .gallerry-wrap .gallerry-image {
    position: relative;
    overflow: hidden;
    display: block;
    margin-bottom: 20px; }
    .gallerry-wrap .gallerry-image:last-child {
      margin-bottom: 0; }
    @media (max-width: 992px) {
      .gallerry-wrap .gallerry-image {
        margin-bottom: 20px; }
        .gallerry-wrap .gallerry-image:last-child {
          margin-bottom: 20px; } }
    .gallerry-wrap .gallerry-image img {
      display: block;
      transition: all 0.6s ease;
      transform-origin: bottom right; }
    .gallerry-wrap .gallerry-image:hover img {
      transform: scale(1.2); }
    .gallerry-wrap .gallerry-image:hover .gallery-item-overlay {
      opacity: 1;
      background: rgba(202, 190, 159, 0.8); }
    .gallerry-wrap .gallerry-image .gallery-item-overlay {
      position: absolute;
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      opacity: 0;
      color: #fff;
      top: 0;
      bottom: 0;
      transition: all 0.6s ease; }
      .gallerry-wrap .gallerry-image .gallery-item-overlay i {
        color: #fff;
        font-size: 25px;
        z-index: 1;
        opacity: 1; }

.payment-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.vertical-divider {
  width: 1px;
  /* Largeur fixe pour la bordure verticale */
  height: 50px;
  /* Hauteur relative au parent */
  background-color: #cabe9f;
  /* Couleur de la bordure */
  align-self: center;
  /* Centrage vertical dans le conteneur */
  margin: 0 20px;
  /* Espace autour du diviseur pour une meilleure visibilité */ }

@media (min-width: 768px) {
  .payment-section {
    flex-direction: row; }
  .vertical-divider {
    display: block;
    /* Assurez-vous que le diviseur est toujours visible sur les écrans larges */ } }

@media (max-width: 767px) {
  .vertical-divider {
    display: block;
    /* Cachez la bordure sur les petits écrans, remplacée par des marges si nécessaire */
    width: 50px;
    /* Largeur fixe pour la bordure verticale */
    height: 1px;
    /* Hauteur relative au parent */
    background-color: #cabe9f;
    /* Couleur de la bordure */
    align-self: center;
    /* Centrage vertical dans le conteneur */
    margin: 0 20px;
    /* Espace autour du diviseur pour une meilleure visibilité */ } }
