@import '../../../../public/assets/scss/base/variables';
.the-building-wrap {
  .text-description {
    padding: 0 16%;
    [class*='col-']:first-child {
      border-right: 1px solid $border-color;
    }
    @media (max-width: 992px) {
      padding: 0 15px;
    }
  }
  .box-counter {
    position: relative;
    &:before {
      content: '';
      background: $primary;
      height: 3px;
      width: 100px;
      right: 0;
      top: 50%;
      position: absolute;
      right: -20%;
      @media (max-width: 992px) {
        content: none;
      }
    }
  }
  .counter-wrap {
    [class*='col-']:last-child {
      .box-counter {
        &:before {
          content: none;
        }
      }
    }
  }
}
