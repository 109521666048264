@import "../../../../public/assets/scss/base/variables";
.block-table{
    max-width: 980px;
    margin: 0 auto;
    text-align: center;
    text-transform: uppercase;
    font-weight: 500;
    .table {
        th {
            text-align: center;
            font-size: 12px;
            font-weight: 600;
            letter-spacing: 0.2em;
            padding: 2em 1em;
            border-bottom: 1px solid #959595;
            white-space: nowrap;
            border-top: 0;
            color: $headings-color;
        }
        td {
            padding: 1em 1em;
            border-bottom: 1px solid #ebebeb;
            color: $headings-color;
            span {
                font-size: 13px;
                font-weight: 400;
                color: $body-color;
            }
            a{
                background-color: #f8f8f8;
                border: 0;
                white-space: nowrap;
                font-size: 12px;
                color: $headings-color;
                font-weight: bold;
                letter-spacing: 0.2em;
                padding: 0.75rem 2rem;
                &:hover{
                    transition: all 0.3ms ease-in-out;
                    background-color: $primary;
                    color: $white;
                }
            }
        }
        tr:first-child{
            td{
                padding-top: 2rem;
            }
        }
    }
}